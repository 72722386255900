/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
import * as React from 'react';
import { useI18n } from '@oneaudi/i18n-context';
import { Text, useStyledTheme } from '@audi/audi-ui-react';
import styled from 'styled-components';
import { messages } from '../../../i18n/messages';
import { isTranslationLoaded } from '../../../lib/isTranslationLoaded';
import { useMedia } from '../../../lib/hooks';
import { openCloseDialog } from '../../../lib/openCloseDialog';
import { VOICE_CALL } from '../Icons';
import { ImprintModal } from '../ImprintModal';
import { ButtonAlignedLeft } from '../PartnerBusinessCardAddress';
import { useTrackingManager } from '../../useTrackingManager';
import { VtpDealer } from '../PartnerBusinessCard';

interface Props {
  vtpData: {
    imprint?: string;
    vtpDealer: VtpDealer;
  };
  pbcRef: React.RefObject<HTMLDialogElement>;
}

const CustomButton = styled(ButtonAlignedLeft)`
  margin: 0 0 16px 0;
`;

const NationwideSellingContainer = styled.div`
  display: grid;
  .nationwide-selling-label,
  .nationwide-selling-new-car-info-text {
    margin: 0 0 16px 0;
  }
`;

export const NationwideSellingVehicle: React.FC<Props> = ({
  vtpData,
  pbcRef,
}): React.ReactElement => {
  const trackingManager = useTrackingManager();
  const theme = useStyledTheme();
  const isViewportXS = useMedia(`only screen and (max-width: ${theme.breakpoints.s}px)`);

  const vtpTitle = useI18n(messages.vtpHeadline);
  const showTitle = isTranslationLoaded(messages.vtpHeadline.id, vtpTitle);
  const nameVariant = () => {
    if (showTitle) return 'order4';
    return isViewportXS ? 'order3' : 'order2';
  };

  const nameAs = () => {
    if (showTitle) return 'p';
    return isViewportXS ? 'h3' : 'h2';
  };

  const phoneLabel1 = useI18n(messages.vtpNationwideSellingPhoneLabel1);

  const hasPhone = isTranslationLoaded(messages.vtpNationwideSellingPhoneLabel1.id, phoneLabel1);

  return (
    <NationwideSellingContainer>
      <Text
        as={nameAs()}
        variant={nameVariant()}
        weight={showTitle ? 'normal' : 'bold'}
        data-testid="nationwide-selling-label"
        className="nationwide-selling-label"
      >
        {useI18n(messages.vtpNationwideSellingLabel)}
      </Text>
      <Text
        as="p"
        variant="copy2"
        weight="normal"
        data-testid="nationwide-selling-new-car-info-text"
        className="nationwide-selling-new-car-info-text"
      >
        {useI18n(messages.vtpNationwideSellingNewCarInfoText)}
      </Text>

      {hasPhone && (
        <CustomButton
          variant="text"
          icon={VOICE_CALL}
          spaceStackStart="s"
          spaceStackEnd="l"
          href={`tel:${useI18n(messages.vtpNationwideSellingPhoneNumber)}`}
          data-testid="phone"
          onClick={() => trackingManager.click('call', '', 'link', 'Anrufen', '', '2', 'phone')}
        >
          {`${useI18n(messages.vtpNationwideSellingPhoneLabel1)} ${useI18n(messages.vtpNationwideSellingPhoneNumber)} ${useI18n(messages.vtpNationwideSellingPhoneLabel2)}`}
        </CustomButton>
      )}

      {vtpData?.imprint && (
        <>
          <CustomButton
            variant="text"
            icon="address-book"
            onClick={() => {
              openCloseDialog(pbcRef.current as HTMLDialogElement);
            }}
            data-test-id="imprint-cta"
          >
            {useI18n(messages.imprintMessage)}
          </CustomButton>
          <ImprintModal imprintRef={pbcRef} imprintData={vtpData.imprint} />
        </>
      )}
    </NationwideSellingContainer>
  );
};
