/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/prop-types */
import * as React from 'react';
import { useRef, useEffect } from 'react';
import styled from 'styled-components';
import {
  Layout,
  LayoutItem,
  Text,
  AccordionSection,
  Accordion,
  useStyledTheme,
  Theme,
} from '@audi/audi-ui-react';
import { useI18n } from '@oneaudi/i18n-context';
import { messages } from '../../i18n/messages';
import { DepartmentOpeningHours, PbcPartner } from './PartnerData';
import { useMedia } from '../../lib/hooks';
import { OpeningHoursDataList } from './OpeningHoursDataList';
import { OpeningHoursLabel } from './OpeningHoursLabel';
import { LayoutContainer } from './LayoutContainer';
import { canUseDOM, throttle } from '../../lib/utils';
import { useTrackingManager } from '../useTrackingManager';

// SC

// @todo: fix sizing as soon as text is translated
const DepartmentsGrid = styled.div`
  display: flex;
  max-width: 100%;
  flex-wrap: wrap;
`;

const DepartmentsGridItem = styled.div`
  box-sizing: border-box;
  width: 318px;
  padding-bottom: 3rem;
  margin-right: 2rem;
`;

const OpeningHoursNoteContainer = styled(LayoutContainer)(
  ({ theme: { breakpoints, colors } }: { theme: Theme }) => `
  @media (min-width: ${breakpoints.m}px) {
    border-top: 1px solid var(${colors?.base?.grey[50]});
  }
`,
);
const OpeningHoursMainContainer = styled(LayoutContainer)(
  ({ theme: { breakpoints } }: { theme: Theme }) => `
  @media (min-width: ${breakpoints.m}px) {
    max-width: 100%;
  }
`,
);

// Components

interface OpeningHoursProps {
  data: PbcPartner;
  hasHeadline?: boolean;
  isNarrow?: boolean;
}
// eslint-disable-next-line
export const OpeningHours: React.FunctionComponent<OpeningHoursProps> = ({
  data,
  hasHeadline = true,
  isNarrow = false,
}) => {
  const theme = useStyledTheme();
  const isViewportM = useMedia(`only screen and (min-width: ${theme.breakpoints.m}px) `);

  const gridElement = useRef<HTMLDivElement>(null);
  const renderAsAccordion = !isViewportM || isNarrow;

  useEffect(() => {
    const $gridContainer = gridElement.current;
    const $gridCellHeaders = $gridContainer?.querySelectorAll<HTMLDivElement>(
      '[data-testid*="grid-head"]',
    );
    // eslint-disable-next-line no-undef
    const columnConform = (elements: NodeListOf<HTMLDivElement> | undefined): void => {
      if (!elements) {
        return;
      }

      const elementCollection: Record<string, { height: number; elements: HTMLDivElement[] }> =
        Array.from(elements).reduce((acc, curr) => {
          // eslint-disable-next-line no-param-reassign
          curr.style.height = 'auto';
          acc[`${curr.offsetTop}`] = acc[`${curr.offsetTop}`] || {
            height: 0,
            elements: [],
          };
          acc[`${curr.offsetTop}`].height =
            curr.clientHeight > acc[`${curr.offsetTop}`].height
              ? curr.clientHeight
              : acc[`${curr.offsetTop}`].height;
          acc[`${curr.offsetTop}`].elements.push(curr);
          return acc;
        }, {} as any);

      Object.keys(elementCollection).forEach((key) =>
        elementCollection[key].elements.forEach((el) => {
          // eslint-disable-next-line no-param-reassign
          el.style.height = `${elementCollection[key].height}px`;
        }),
      );
    };
    columnConform($gridCellHeaders);
    const throttledColumnConform = throttle(() => {
      columnConform($gridCellHeaders);
    }, 200);
    if (canUseDOM) {
      window.addEventListener('resize', throttledColumnConform);
    }
    return () => {
      if (canUseDOM) {
        window.removeEventListener('resize', throttledColumnConform);
      }
    };
  }, []);

  const now = new Date();
  const trackingManager = useTrackingManager();
  let departments: DepartmentOpeningHours[] = [];
  let openingHoursNote;

  if (data.openingHours) {
    departments = data.openingHours.departments || [];
    openingHoursNote = data.openingHours.openingHoursNote;
  }

  return (
    <OpeningHoursMainContainer>
      {hasHeadline && (
        <Text
          as="h2"
          variant="order3"
          weight="normal"
          data-testid="openingHours-headline"
          spaceStackEnd="xl"
        >
          {useI18n(messages.openingHoursHeadline)}
        </Text>
      )}
      {renderAsAccordion && (
        <Accordion multiple>
          {departments.map((department: DepartmentOpeningHours, i: number) => (
            <AccordionSection
              label={(<OpeningHoursLabel department={department} now={now} isNarrow />) as any}
              headingLevel="h3"
              id={`department${i}`}
              // eslint-disable-next-line react/no-array-index-key
              key={i}
              defaultOpen={departments.length === 1}
              onToggle={() =>
                trackingManager.click(
                  'content',
                  '',
                  'accordion',
                  department.departmentName,
                  data.nameEnhanced,
                  (i + 1).toString(),
                  department.departmentName,
                )
              }
            >
              <Text spaceStackEnd="s">{department.departmentOpeningHoursNote}</Text>
              <OpeningHoursDataList department={department} now={now} />
            </AccordionSection>
          ))}
        </Accordion>
      )}
      {isViewportM && !isNarrow && (
        <DepartmentsGrid ref={gridElement}>
          {departments?.map((department: DepartmentOpeningHours, i: number) => (
            // eslint-disable-next-line react/no-array-index-key
            <DepartmentsGridItem key={i}>
              <Layout direction="column">
                <LayoutItem data-testid={`grid-head-${i}`}>
                  <OpeningHoursLabel department={department} now={now} />
                  <Text spaceStackEnd="l" spaceStackStart="s">
                    {department.departmentOpeningHoursNote}
                  </Text>
                </LayoutItem>
                <LayoutItem data-testid="title">
                  <OpeningHoursDataList department={department} now={now} />
                </LayoutItem>
              </Layout>
            </DepartmentsGridItem>
          ))}
        </DepartmentsGrid>
      )}
      {openingHoursNote && (
        <OpeningHoursNoteContainer>
          <Text variant="copy2" spaceStackStart="m">
            {openingHoursNote}
          </Text>
        </OpeningHoursNoteContainer>
      )}
    </OpeningHoursMainContainer>
  );
};
