/* eslint-disable */
import { queryString } from '../query';
import { TimeRange, OpeningHours, OpeningHoursRelated, Department } from './types';
import { useClient } from '../lib/client';
import { GfaLocaleServiceV1 } from '@volkswagen-onehub/gfa-locale-service';

export function pruneOpeningHours(
  openingHours: OpeningHoursRelated | undefined,
): OpeningHoursRelated | undefined {
  if (openingHours === undefined) {
    return openingHours;
  }

  // RegEx to validate time string in format HH:MM:SS (00:00:00 - 23:59:59)
  const timeRegEx = /^([0-1][0-9]|2[0-3]){1}(:[0-5][0-9]){1,2}$/g;

  const departments = openingHours?.departments?.filter((department: Department) => {
    if (department.openingHours && department.openingHours.length > 0) {
      return department.openingHours.every((openingHour: OpeningHours) => {
        if (openingHour.timeRanges && openingHour.timeRanges.length > 0) {
          return openingHour.timeRanges.every((timeRange: TimeRange) => {
            if (
              timeRange.openTime?.match(timeRegEx) === null ||
              timeRange.closeTime?.match(timeRegEx) === null
            ) {
              // eslint-disable-next-line no-console
              console.debug(
                `Detected wrong format in department opening times! Department is filtered out.\nAffected department: ${
                  department.id ? department.id : department.departmentName
                }\nEntry: ${openingHour.id}`,
              );
              return false;
            }
            return true;
          });
        }
        return true;
      });
    }
    return true;
  });

  return { ...openingHours, departments };
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const getPartnerData = async (partnerId: string, locale: GfaLocaleServiceV1) => {
  const client = useClient(locale);

  const getData = async () => {
    return client
      .query({
        query: queryString(partnerId) as any,
      })
      .then((result) => {
        const { dealerById } = result.data;
        if (dealerById.openingHours) {
          dealerById.openingHours = pruneOpeningHours(dealerById.openingHours);
        }
        return dealerById;
      });
  };

  const data = await getData();
  return data;
};
