export const messages = {
  fax: {
    defaultMessage: 'Fax',
    id: 'partnerBusinessCard.fax',
  },
  phone: {
    defaultMessage: 'Phone',
    id: 'partnerBusinessCard.phone',
  },
  directionsLinkLabel: {
    defaultMessage: 'Directions',
    id: 'partnerBusinessCard.directionsLinkLabel',
  },
  vCardLinkLabel: {
    defaultMessage: 'Download contact data',
    id: 'partnerBusinessCard.vCardLinkLabel',
  },
  linkLabel: { defaultMessage: 'More info', id: 'partnerBusinessCard.linkLabel' },
  myAudiLinkLabel: {
    defaudefaultMessagelt: 'Save in myAudi',
    id: 'partnerBusinessCard.myAudiLinkLabel',
  },
  addressAndContactLabel: {
    defaultMessage: 'Address',
    id: 'partnerBusinessCard.addressAndContact.label',
  },
  partnerServiceSales: { defaultMessage: 'Car sales', id: 'service.sales' },
  partnerServiceSalesR8: { defaultMessage: 'R8 partner', id: 'service.sales-r8' },
  partnerServiceService: { defaultMessage: 'Service partner', id: 'service.service' },
  partnerServiceEtron: { defaultMessage: 'e-tron', id: 'service.e-tron' },
  partnerServiceUsedcars: {
    defaultMessage: 'Audi Approved :plus',
    id: 'service.audi-approved-plus',
  },
  openingHoursHeadline: { defaultMessage: 'Opening hours', id: 'openingHours.headline' },
  openingHoursLabelOpenUntil: { defaultMessage: 'Open until', id: 'openingHours.labelOpenUntil' },
  openingHoursLabelClosesSoon: {
    defaultMessage: 'Closes soon',
    id: 'openingHours.labelClosesSoon',
  },
  openingHoursLabelClosed: { defaultMessage: 'Closed', id: 'openingHours.labelClosed' },
  openingHoursLabelWillOpenAt: { defaultMessage: 'Opens', id: 'openingHours.labelWillOpenAt' },
  openingHoursLabelStatusIsUnknown: {
    defaultMessage: 'No opening hours available',
    id: 'openingHours.labelStatusIsUnknown',
  },
  openingHoursDayClosed: { defaultMessage: 'Closed', id: 'openingHours.dayClosed' },
  openingHoursLabel: { defaultMessage: 'Opening hours', id: 'openingHours.label' },
  ctaLabelRequestTestdrive: {
    defaultMessage: 'Book a test drive',
    id: 'cta.labelRequestTestdrive',
  },
  ctaLabelScheduleService: {
    defaultMessage: 'Request a service appointment',
    id: 'cta.labelScheduleService',
  },
  ctaLabelPbcAnchor: { defaultMessage: 'Overall contact', id: 'cta.labelPbcAnchor' },
  ctaLabelContactUs: { defaultMessage: 'Contact us', id: 'cta.labelContactUs' },
  missingPartnerDataErrorHeader: {
    defaultMessage: 'Error',
    id: 'partnerBusinessCard.missingPartnerDataErrorHeader',
  },
  missingPartnerDataErrorMessage: {
    defaultMessage: 'Partner data does not exist.',
    id: 'partnerBusinessCard.missingPartnerDataErrorMessage',
  },
  imprintMessage: {
    defaultMessage: 'Further provider information',
    id: 'nemo.ui.sc.details.dealer.imprint',
  },
  registrationNumber: {
    defaultMessage: 'Reg.:',
    id: 'fa-partner-business-card_registration_number',
  },
  imprintTitle: {
    defaultMessage: 'Available at the following stores:',
    id: 'fa-partner-business-imprint_title',
  },
  vtpHeadline: {
    // defaultMessage: 'Dealer Information',
    id: 'nemo.ui.sc.details.dealer.contact.headline',
  },
  vtpInvestorSharedLabel: {
    defaultMessage: 'Vehicles for sale in certain shops {{originalGroupName}}',
    id: 'nemo.ui.sc.investor-shared-vehicle.label',
    placeholder: {
      originalGroupName: '',
    },
  },
  vtpInvestorSharedInfoText: {
    defaultMessage:
      'For further information on this vehicle, please contact your local dealer using the enquiry form.',
    id: 'nemo.ui.sc.details.dealer.investor-shared-vehicle.infotext',
  },
  vtpInvestorSharedPhoneLabel1: {
    id: 'nemo.ui.sc.details.dealer.investor-shared-vehicle.phone-label1',
  },
  vtpInvestorSharedPhoneLabel2: {
    defaultMessage: '(free of charge)',
    id: 'nemo.ui.sc.details.dealer.investor-shared-vehicle.phone-label2',
  },
  vtpInvestorSharedPhoneNumber: {
    defaultMessage: '[excluding public holidays] {{phoneNumber}}',
    id: 'nemo.ui.sc.details.dealer.investor-shared-vehicle.phone-number',
    placeholder: {
      phoneNumber: '',
    },
  },
  vtpInvestorSharedDealerChain: {
    defaultMessage: 'See dealer information',
    id: 'sc.fa-dealer-chain.headline',
  },
  vtpNationwideSellingLabel: {
    defaultMessage: 'AUDI AG',
    id: 'nemo.ui.sc.nationwideselling.label',
  },
  vtpNationwideSellingPhoneLabel1: {
    id: 'nemo.ui.sc.details.dealer.nationwideselling.phone-label1',
  },
  vtpNationwideSellingPhoneLabel2: {
    defaultMessage: '(free of charge)',
    id: 'nemo.ui.sc.details.dealer.nationwideselling.phone-label2',
  },
  vtpNationwideSellingPhoneNumber: {
    defaultMessage: '### ### ###',
    id: 'nemo.ui.sc.details.dealer.nationwideselling.phone-number',
  },
  vtpNationwideSellingNewCarInfoText: {
    defaultMessage: 'Nationwide selling vehicle info text for new cars.',
    id: 'nemo.ui.sc.details.dealer.nationwideselling.infotext',
  },
  vtpNationwideSellingUsedCarInfoText: {
    defaultMessage: 'Nationwide selling vehicle info text for used cars.',
    id: 'nemo.ui.sc.uc.details.dealer.nationwideselling.infotext',
  },
};
