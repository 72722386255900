/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import * as React from 'react';
import { useI18n } from '@oneaudi/i18n-context';
import { Text, useStyledTheme } from '@audi/audi-ui-react';
import styled from 'styled-components';
import { Dealer } from '../PartnerBusinessCardTag';
import { messages } from '../../../i18n/messages';
import { isTranslationLoaded } from '../../../lib/isTranslationLoaded';
import { useMedia } from '../../../lib/hooks';
import { openCloseDialog } from '../../../lib/openCloseDialog';
import { VOICE_CALL } from '../Icons';
import { ImprintModal } from '../ImprintModal';
import { ButtonAlignedLeft } from '../PartnerBusinessCardAddress';
import { useTrackingManager } from '../../useTrackingManager';
import { DealerPhone, VtpDealer } from '../PartnerBusinessCard';

interface Props {
  vtpData: {
    imprint?: string;
    registrationNumber?: string;
    dealers: Dealer[];
    investorSharedVehicle: boolean;
    dynamicLabel?: string;
    vtpDealer: VtpDealer;
  };
  pbcRef: React.RefObject<HTMLDialogElement>;
}

const CustomButton = styled(ButtonAlignedLeft)`
  margin: 0 0 16px 0;
`;

const InvestorSharedContainer = styled.div`
  display: grid;
  .investor-shared-label,
  .investor-shared-info-text {
    margin: 0 0 16px 0;
  }
`;

export const InvestorSharedVehicle: React.FC<Props> = ({ vtpData, pbcRef }): React.ReactElement => {
  const trackingManager = useTrackingManager();
  const theme = useStyledTheme();
  const isViewportXS = useMedia(`only screen and (max-width: ${theme.breakpoints.s}px)`);

  const vtpTitle = useI18n(messages.vtpHeadline);
  const showTitle = isTranslationLoaded(messages.vtpHeadline.id, vtpTitle);
  const nameVariant = () => {
    if (showTitle) return 'order4';
    return isViewportXS ? 'order3' : 'order2';
  };

  const nameAs = () => {
    if (showTitle) return 'p';
    return isViewportXS ? 'h3' : 'h2';
  };
  const phoneLabel1 = useI18n(messages.vtpInvestorSharedPhoneLabel1);
  const phone = vtpData.vtpDealer.phoneNumbers?.filter(
    (dealerPhone: DealerPhone) => dealerPhone.type === 'phone',
  )[0].number;

  const hasPhone =
    phone !== '' && isTranslationLoaded(messages.vtpInvestorSharedPhoneLabel1.id, phoneLabel1);
  const originalGroupName = vtpData?.dynamicLabel;

  return (
    <InvestorSharedContainer>
      <Text
        as={nameAs()}
        variant={nameVariant()}
        weight={showTitle ? 'normal' : 'bold'}
        data-testid="investor-shared-label"
        className="investor-shared-label"
      >
        {originalGroupName
          ? useI18n(messages.vtpInvestorSharedLabel, { originalGroupName })
          : useI18n(messages.vtpInvestorSharedLabel)}
      </Text>
      <Text
        as="p"
        variant="copy2"
        weight="normal"
        data-testid="investor-shared-info-text"
        className="investor-shared-info-text"
      >
        {useI18n(messages.vtpInvestorSharedInfoText)}
      </Text>

      {hasPhone && (
        <CustomButton
          variant="text"
          icon={VOICE_CALL}
          spaceStackStart="s"
          spaceStackEnd="l"
          href={`tel:${phone}`}
          data-testid="phone"
          onClick={() => trackingManager.click('call', '', 'link', 'Anrufen', '', '2', 'phone')}
        >
          {`${useI18n(messages.vtpInvestorSharedPhoneLabel1)} ${useI18n(messages.vtpInvestorSharedPhoneNumber, { phoneNumber: phone! })} ${useI18n(messages.vtpInvestorSharedPhoneLabel2)}`}
        </CustomButton>
      )}

      {vtpData?.dealers?.length > 0 && (
        <>
          <CustomButton
            variant="text"
            icon="address-book"
            onClick={() => {
              openCloseDialog(pbcRef.current as HTMLDialogElement);
            }}
            data-test-id="imprint-cta"
          >
            {useI18n(messages.vtpInvestorSharedDealerChain)}
          </CustomButton>
          <ImprintModal imprintRef={pbcRef} imprintData={vtpData?.dealers} />
        </>
      )}
    </InvestorSharedContainer>
  );
};
