/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { Buffer } from 'buffer';

// eslint-disable-next-line
export const createDirectionsLink = ({ name, address, dealerId, locale }: any): string => {
  const destination = encodeURIComponent(
    `${name},${address},${dealerId ? dealerId.substring(0, 2) : locale}`.replace(/&amp;/g, '&'),
  ).replace(/%20/g, '+');
  return `https://www.google.com/maps/dir/?api=1&destination=${destination}`;
};

// eslint-disable-next-line
export const createMyAudiLink = ({ dealerId }: any): string | undefined => {
  if (!dealerId) return undefined;
  const tenant = dealerId.substring(0, 2);
  const kvpsid = dealerId.substring(4, 9);
  return `https://my.audi.com/partner/details/${Buffer.from(
    `Partner:{"tenant":"${tenant}","kvpsid":"${kvpsid}"}`,
  ).toString('base64')}`;
};
