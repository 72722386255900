import * as React from 'react';
import { useI18n } from '@oneaudi/i18n-context';
import { Container, Content, ImprintContainer, CloseButton, Title, ImprintText } from './style';
import { openCloseDialog } from '../../../lib/openCloseDialog';
import { messages } from '../../../i18n/messages';
import { Dealer } from '../PartnerBusinessCardTag';

interface ImprintProps {
  imprintData: string | Dealer[] | undefined;
  imprintRef: React.RefObject<HTMLDialogElement>;
}

export const ImprintModal: React.FC<ImprintProps> = ({ imprintData, imprintRef }) => {
  const renderHTML = (rawHTML: string) =>
    React.createElement('span', { dangerouslySetInnerHTML: { __html: rawHTML } });

  const renderCorrectImprintData = (imprint: string | Dealer[]): React.ReactElement => {
    if (typeof imprint === 'object') {
      return (
        <>
          {imprint.map((imprintObj: Dealer) => (
            <ImprintText variant="copy1" key={Math.random()} marginBottom>
              {imprintObj.name}
            </ImprintText>
          ))}
        </>
      );
    }
    return <ImprintText variant="copy1">{renderHTML(imprintData as string)}</ImprintText>;
  };

  return (
    <Container data-testid="imprint-modal" id="dialog" ref={imprintRef}>
      <Content>
        <ImprintContainer data-testid="imprint-text">
          <CloseButton
            variant="icon-tertiary"
            icon="cancel"
            onClick={() => {
              openCloseDialog(imprintRef.current as HTMLDialogElement);
            }}
            data-testid="imprint-closeButton"
          />
          <Title variant="order2" weight="normal">
            {useI18n(messages.imprintTitle)}
          </Title>
          {imprintData && renderCorrectImprintData(imprintData)}
        </ImprintContainer>
      </Content>
    </Container>
  );
};

export default ImprintModal;
