import * as React from 'react';
import { Fragment } from 'react';
import styled from 'styled-components';
import { Button, LayoutItem, Text, useStyledTheme } from '@audi/audi-ui-react';
import { useI18n } from '@oneaudi/i18n-context';
import ImprintModal from './ImprintModal';
import { VariantConfig, VtpDealer } from './PartnerBusinessCard';
import { GEO_FENCE } from './Icons';
import { useTrackingManager } from '../useTrackingManager';
import { JsonLd } from './Json-ld';
import { PartnerContext } from '../PartnerProvider';
import { useMedia } from '../../lib/hooks';
import { messages } from '../../i18n/messages';
import { openCloseDialog } from '../../lib/openCloseDialog';
import { isTranslationLoaded } from '../../lib/isTranslationLoaded';
import { InvestorSharedVehicle } from './VTPCards/InvestorSharedVehicle';
import { BevAgencyDealer } from './VTPCards/BevAgencyDealer';
import { BevAgencyVehicle } from './VTPCards/BevAgencyVehicle';
import { NationwideSellingVehicle } from './VTPCards/NationwideSellingVehicle';
import { createDirectionsLink } from './PartnerLinks';

const NULL_VTP_CONFIG = {
  isBevAgency: false,
  isBEVAgency: false,
  isBEVAgencyDealer: false,
  investorSharedVehicle: false,
  nationWideSelling: false,
  imprint: '',
  registrationNumber: '',
  dealers: [],
  locale: '',
  vtpDealer: {
    city: '',
    dealerContextLinkData: [
      {
        context: '',
        dealerId: '',
        geoLocation: {
          lat: 0,
          lon: 0,
        },
        groupName: '',
        hasDealerAddressData: false,
        originalGroupName: '',
      },
    ],
    email: '',
    geoLocation: {
      lat: 0,
      lon: 0,
    },
    id: '',
    isAffiliateCompany: false,
    name: '',
    phoneNumbers: [
      {
        number: '',
        type: '',
      },
    ],
    street: '',
    trackingId: '',
    zipCode: '',
  },
  dynamicLabel: '',
};

interface Props {
  showTitle: boolean;
}

const StyledHeadlineAsLink = styled.div`
  > a {
    border-bottom: 0;
  }
`;

const AddressContainer = styled(LayoutItem)<Props>`
  display: grid;

  .pbc-partner-name {
    font-weight: ${(props) => (props.showTitle ? 'normal' : 'bold')};
    margin: 0 0 24px;
  }

  .investor-shared {
    margin: 0 0 16px 0;
  }

  dialog {
    border: none;
    display: block;
    inset: 0;
    margin: 0 auto;
    max-height: 100%;
    max-width: 1024px;
    overflow: auto;
    padding: 0;
    transition: opacity 0.25s;
  }

  dialog:not([open]) {
    opacity: 0;
    pointer-events: none;
  }

  dialog::backdrop {
    background-color: rgba(0, 0, 0, 0.5);
  }
  dialog:not([open]) {
    background-color: #fff;
  }
  h2,
  h3 {
    margin: 0 0 24px;
  }
`;

const RegNumber = styled(Text)`
  margin: 0 0 24px;
`;

export interface Dealer {
  id: string;
  name: string;
}
const Wrapper = styled.div`
  max-width: 375px;
`;

interface PartnerBusinessCardTagProps {
  variantConfig: VariantConfig;
  unitTest?: boolean;
  vtpData: {
    imprint?: string;
    registrationNumber?: string;
    dealers: Dealer[];
    isBevAgency?: boolean;
    isBEVAgency?: boolean;
    isBEVAgencyDealer?: boolean;
    investorSharedVehicle: boolean;
    nationWideSelling: boolean;
    vtpDealerResultsURL?: string;
    locale: string;
    vtpDealer: VtpDealer;
    dynamicLabel: string | undefined;
  };
}

export const PartnerBusinessCardTag: React.FC<PartnerBusinessCardTagProps> = ({
  variantConfig,
  vtpData = NULL_VTP_CONFIG,
  unitTest = false,
}): React.ReactElement => {
  const { partner } = React.useContext(PartnerContext);
  const pbcRef = React.useRef<HTMLDialogElement>(null);
  const trackingManager = useTrackingManager();
  // eslint-disable-next-line
  const { displayOfficialName, isGoogleMapLink, isLinkedToPWS, showJsonLd } =
    // eslint-disable-next-line
    variantConfig.contactDetails || variantConfig;
  const { isBevAgency, isBEVAgency, isBEVAgencyDealer, investorSharedVehicle, nationWideSelling } =
    vtpData;

  const partnerName = displayOfficialName ? partner.officialName : partner.nonOfficialName;

  const partnerAddressSize = partner.address && partner.address.length;

  const partnerAddress = partnerAddressSize
    ? partner.address.map((item, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <Fragment key={index}>{`${item}${index === partnerAddressSize - 1 ? '' : ', '}`}</Fragment>
      ))
    : [];

  const theme = useStyledTheme();
  const isViewportXS = useMedia(`only screen and (max-width: ${theme.breakpoints.s}px)`);

  const vtpTitle = useI18n(messages.vtpHeadline);
  const showTitle = isTranslationLoaded(messages.vtpHeadline.id, vtpTitle);
  let vtpDealerObj: VtpDealer | null = null;
  let dealerAddress: string | null = null;
  let dealerDirectionUrl: string | null = null;

  if (vtpData && vtpData.vtpDealer) {
    vtpDealerObj = vtpData.vtpDealer;
    dealerAddress =
      vtpDealerObj && `${vtpDealerObj.street}, ${vtpDealerObj.zipCode} ${vtpDealerObj.city}`;
    dealerDirectionUrl = createDirectionsLink({
      ...vtpData.vtpDealer,
      locale: vtpData.locale,
      address: dealerAddress,
    });
  }

  const nameVariant = () => {
    if (showTitle) return 'order4';
    return isViewportXS ? 'order3' : 'order2';
  };

  const nameAs = () => {
    if (showTitle) return 'p';
    return isViewportXS ? 'h3' : 'h2';
  };

  const className = () => {
    if (investorSharedVehicle) return 'investor-shared';
    return '';
  };

  const isDealerInfoIntegrated =
    isBevAgency || isBEVAgency || isBEVAgencyDealer || investorSharedVehicle || nationWideSelling;

  const vehicleCard = (() => {
    if (isBevAgency || isBEVAgency) {
      return <BevAgencyVehicle vtpData={vtpData} pbcRef={pbcRef} />;
    }
    if (isBEVAgencyDealer) {
      return (
        <BevAgencyDealer
          vtpData={vtpData}
          partnerName={partnerName}
          pbcRef={pbcRef}
          isGoogleMapLink
          partnerAddress={partnerAddress}
        />
      );
    }
    if (investorSharedVehicle) {
      return <InvestorSharedVehicle vtpData={vtpData} pbcRef={pbcRef} />;
    }
    if (nationWideSelling) {
      return <NationwideSellingVehicle vtpData={vtpData} pbcRef={pbcRef} />;
    }
    return '';
  })();

  if (unitTest) {
    partner.partnerId = 'DEUA26603';
  }

  return (
    <AddressContainer showTitle={showTitle}>
      {showTitle && (
        <Text
          as={isViewportXS ? 'h3' : 'h2'}
          variant="order3"
          weight="normal"
          data-testid="vtp-title"
          className={className()}
        >
          {vtpTitle}
        </Text>
      )}
      {isDealerInfoIntegrated && vehicleCard}
      {!isDealerInfoIntegrated && partner && partner.partnerId !== '' && (
        <>
          {showJsonLd && <JsonLd data={partner} />}
          <Text
            as={nameAs()}
            variant={nameVariant()}
            weight={showTitle ? 'normal' : 'bold'}
            data-testid="name"
            className="pbc-partner-name"
          >
            {isLinkedToPWS && partner.url && !vtpData?.imprint ? (
              <StyledHeadlineAsLink>
                <a href={partner.url} target="_blank" rel="noreferrer">
                  {vtpDealerObj?.name || partnerName}
                </a>
              </StyledHeadlineAsLink>
            ) : (
              vtpDealerObj?.name || partnerName
            )}
          </Text>

          <Wrapper>
            {vtpData?.registrationNumber && (
              <RegNumber variant="copy2">
                {useI18n(messages.registrationNumber)} {vtpData?.registrationNumber}
              </RegNumber>
            )}
            {isGoogleMapLink && (
              <Button
                variant="text"
                icon={GEO_FENCE}
                href={dealerDirectionUrl || partner.directionsUrl}
                target="_blank"
                rel="noopener noreferrer"
                onClick={() =>
                  trackingManager.click(
                    'exit_link',
                    dealerDirectionUrl || partner.directionsUrl,
                    'link',
                    dealerAddress || `${partner.address[0]} ${partner.address[1]}`,
                    partnerName,
                    '1',
                    'location',
                  )
                }
                data-testid="directions"
                spaceStackStart="s"
                spaceStackEnd="l"
              >
                {dealerAddress || partnerAddress}
              </Button>
            )}
            {vtpData?.imprint && (
              <Button
                variant="text"
                icon="address-book"
                onClick={() => {
                  openCloseDialog(pbcRef.current as HTMLDialogElement);
                }}
                data-testid="imprint-cta"
              >
                {useI18n(messages.imprintMessage)}
              </Button>
            )}

            <ImprintModal imprintRef={pbcRef} imprintData={vtpData?.imprint} />
          </Wrapper>
        </>
      )}
    </AddressContainer>
  );
};
