import { ApolloClient, InMemoryCache } from '@apollo/client';
import { GfaLocaleServiceV1 } from '../app/feature-service-types';

export const useClient = (locale: GfaLocaleServiceV1) => {
  const client = new ApolloClient({
    ssrMode: true,
    uri: 'https://dealer-graphql.apps.emea.vwapps.io/',
    cache: new InMemoryCache(),
    headers: {
      clientId: 'dg552wqh5t',
      'Accept-Language': `${locale.language}-${locale.countryCode.toLocaleUpperCase()}`,
    },
  });

  return client;
};
