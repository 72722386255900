import { Button, Text } from '@audi/audi-ui-react';
import styled from 'styled-components';
import { constants } from '../../../constants';

const device = {
  desktop: `(min-width:${constants.displaySizes.desktop})`,
  largeDesktop: `(min-width:${constants.displaySizes.largeDesktop})`,
  smallDesktop: `(min-width:${constants.displaySizes.smallDesktop})`,
  mobile: `(max-width:${constants.displaySizes.mobile})`,
  tablet: `(min-width: ${constants.displaySizes.tablet})`,
  smallTablet: `(max-width: ${constants.displaySizes.tablet})`,
};

interface ImprintText {
  marginBottom?: boolean;
}

export const Container = styled.dialog`
  height: 100%;
  top: 50% !important;
  transform: translateY(-50%);
  @media ${device.tablet} {
    width: 70%;
    max-width: 1024px;
  }
`;

export const ImprintContainer = styled.div`
  background-color: #fff;
  height: fit-content;
  margin: 0 auto;
  max-width: 1024px;
  padding: 90px 66px 78px;
  position: relative;
  width: auto;
  @media ${device.tablet} {
    width: calc(100% - 132px);
  }
`;

export const Content = styled.div`
  display: grid;
  height: 100%;
`;

export const CloseButton = styled(Button)`
  align-self: baseline;
  justify-self: end;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 1;
`;

export const Title = styled(Text)`
  margin-bottom: 24px;
`;

export const ImprintText = styled(Text)<ImprintText>`
  color: #000;
  margin-bottom: ${(props) => (props.marginBottom ? '12px' : '')};
`;
