import * as React from 'react';
import { LayoutItem } from '@audi/audi-ui-react';

import { OpeningHours } from './OpeningHours';
import { AddressAndContact } from './AddressAndContact';
import { VariantConfig } from './PartnerBusinessCard';
import { PartnerBusinessCardHeader } from './PartnerBusinessCardHeader';
import { JsonLd } from './Json-ld';
import { PartnerContext } from '../PartnerProvider';

export const PartnerBusinessCardFullLong = (
  variantConfig: VariantConfig,
): React.ReactElement | null => {
  const { partner, hasOpeningHours } = React.useContext(PartnerContext);
  const { ctaBarButtonConfig = [] } = variantConfig;
  const { socialMedia = [] } = variantConfig;
  const {
    displayCtaBar,
    displayOfficialName,
    displayEmail,
    displayOpeningHours,
    displayPhone,
    isGoogleMapLink,
    isLinkedToPWS,
    showMoreInfoLink,
    showImage,
    showServiceList,
    showJsonLd,
    showSocialMediaLinks,
    // eslint-disable-next-line
  } = variantConfig.contactDetails || variantConfig;

  return partner && partner.partnerId !== '' ? (
    <>
      {showJsonLd && <JsonLd data={partner} />}
      <PartnerBusinessCardHeader
        partner={partner}
        displayOfficialName={displayOfficialName}
        isLinkedToPWS={isLinkedToPWS}
        showImage={showImage}
        showServiceList={showServiceList}
      />
      <LayoutItem>
        <AddressAndContact
          displayCtaBar={displayCtaBar}
          ctaBarButtonConfig={ctaBarButtonConfig}
          displayEmail={displayEmail}
          displayPhone={displayPhone}
          isGoogleMapLink={isGoogleMapLink}
          isLinkedToPWS={isLinkedToPWS}
          showMoreInfoLink={showMoreInfoLink}
          showImage={showImage}
          showSocialMediaLinks={showSocialMediaLinks}
          socialMedia={socialMedia}
        />
      </LayoutItem>
      {displayOpeningHours && hasOpeningHours && (
        <LayoutItem spaceStackStart="xxl">
          <OpeningHours data={partner} isNarrow />
        </LayoutItem>
      )}
    </>
  ) : null;
};
