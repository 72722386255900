import * as React from 'react';
import styled, { useTheme } from 'styled-components';
import { Button, Layout, LayoutItem, Text } from '@audi/audi-ui-react';
import { VariantConfig } from './PartnerBusinessCard';
import { JsonLd } from './Json-ld';
import { PartnerContext } from '../PartnerProvider';
import { useTrackingManager } from '../useTrackingManager';
import { DIRECT_MAIL, GEO_FENCE, VOICE_CALL } from './Icons';
import { useMedia } from '../../lib/hooks';

interface PartnerBusinessCardAddressProps {
  variantConfig: VariantConfig;
}

const StyledHeadlineAsLink = styled.div`
  margin-bottom: 15px;
  > a {
    border-bottom: 10px;
  }
`;

const AddressContainer = styled(LayoutItem)``;

export const ButtonAlignedLeft = styled(Button)`
  margin-bottom: 15px;

  text-align: left;
  span {
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

export const PartnerBusinessCardAddress: React.FC<PartnerBusinessCardAddressProps> = ({
  variantConfig,
}): React.ReactElement => {
  const { partner } = React.useContext(PartnerContext);
  const trackingManager = useTrackingManager();

  // eslint-disable-next-line
  const { isLinkedToPWS, hasOuterSpacing, isGoogleMapLink, showJsonLd } =
    // eslint-disable-next-line
    variantConfig.contactDetails || variantConfig;
  // eslint-disable-next-line
  let { displayOfficialName, displayEmail, displayPhone } =
    // eslint-disable-next-line
    variantConfig.contactDetails || variantConfig;

  displayOfficialName = (displayOfficialName && partner.officialName) as unknown as boolean;
  displayEmail = (displayEmail && partner.email) as unknown as boolean;
  displayPhone = (displayPhone && partner.phone) as unknown as boolean;
  // eslint-disable-next-line
  const partnerName = displayOfficialName ? partner.officialName : partner.nonOfficialName;

  const theme = useTheme();
  const isViewportXS = useMedia(`only screen and (max-width: ${theme.breakpoints.s}px)`);

  return (
    <AddressContainer spacing={hasOuterSpacing ? ['m'] : undefined}>
      {partner && partner.partnerId !== '' && (
        <>
          {showJsonLd && <JsonLd data={partner} />}
          <Text
            as={isViewportXS ? 'h3' : 'h2'}
            variant={isViewportXS ? 'order3' : 'order2'}
            weight="bold"
            data-testid="name"
          >
            {isLinkedToPWS && partner.url ? (
              <StyledHeadlineAsLink>
                <a href={partner.url} target="_blank" rel="noreferrer">
                  {partnerName}
                </a>
              </StyledHeadlineAsLink>
            ) : (
              <StyledHeadlineAsLink>{partnerName}</StyledHeadlineAsLink>
            )}
          </Text>
          <Layout direction="column">
            {isGoogleMapLink && (
              <ButtonAlignedLeft
                variant="text"
                icon={GEO_FENCE}
                href={partner.directionsUrl}
                onClick={() =>
                  trackingManager.click(
                    'exit_link',
                    partner.directionsUrl,
                    'link',
                    `${partner.address[0]} ${partner.address[1]}`,
                    partnerName,
                    '1',
                    'location',
                  )
                }
                data-testid="directions"
                spaceStackEnd="l"
              >
                {partner.address[0]}
                <br />
                {partner.address[1]}
              </ButtonAlignedLeft>
            )}

            {displayPhone && (
              <ButtonAlignedLeft
                href={`tel:${partner.phone}`}
                data-testid="phone"
                variant="text"
                icon={VOICE_CALL}
                onClick={() =>
                  trackingManager.click(
                    'call',
                    partner.directionsUrl,
                    'link',
                    'Anrufen',
                    partnerName,
                    '2',
                    'phone',
                  )
                }
                spaceStackEnd="l"
              >
                {partner.phone}
              </ButtonAlignedLeft>
            )}
            {partner.email && displayEmail && (
              <ButtonAlignedLeft
                href={`mailto:${partner.email}`}
                variant="text"
                icon={DIRECT_MAIL}
                onClick={() =>
                  trackingManager.click(
                    'email',
                    partner.directionsUrl,
                    'link',
                    partner.email || '',
                    partnerName,
                    '3',
                    'mail',
                  )
                }
                spaceStackEnd="l"
              >
                {partner.email}
              </ButtonAlignedLeft>
            )}
          </Layout>
        </>
      )}
    </AddressContainer>
  );
};
